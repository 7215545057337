.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle.toggle-disabled {
  cursor: not-allowed;
}

.toggle-switch {
  display: inline-block;
  background: var(--bg-500);
  border-radius: 16px;
  width: 52px;
  height: 26px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:disabled {
  background: var(--bg-300);
}

.toggle-switch:before,
.toggle-switch:after {
  content: '';
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.toggle-switch.toggle-disabled:before {
  background: linear-gradient(to bottom, #bbb 0%, #bbb 100%);
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #eee 0%, #eee 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle:hover .toggle-switch.toggle-disabled:before {
  background: linear-gradient(to bottom, #bbb 0%, #bbb 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
  background: var(--green-700);
}

.toggle-checkbox:checked:disabled + .toggle-switch {
  background: var(--green-900);
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
